@use 'src/styles/vars';

.interview-page {
  &__header {
    padding: 0px 45px;

    // balise qui contient l'auteur, la localisation et une image de l'interview
    &__intro {
      display: flex;
      flex-direction: row;
      margin-bottom: 50px;
      
      &__author {
        min-width: 40%;

        &__name {
          margin: 30px 0px 50px 0px;
        }

        &__location {
          font-family: vars.$font-primary;
          font-size: 24.5px;
          max-width: 85%;
        }

        &__translated-from {
          margin-top: 30px;
          font-family: vars.$font-primary;
          font-size: 15px;
          font-style: oblique;
          max-width: 85%;
        }
      }

      &__image {
        position: relative;
        margin-left: 50px;

        &__legend {
          display: none;
          position: absolute;
          bottom: 10px;
          left: 20px;
          transition: 0.2s display;
          font-size: 16px;
          font-family: vars.$font-primary;
          max-width: 80%;
        }

        &:hover &__legend {
          display: block;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__text {
      font-size: 18px;
      columns: 2;
      margin-left: auto;
      max-width: 75%;

      &--one-column {
        margin-left: auto;
        max-width: 75%;
        margin-bottom: 16px;
      }

      &--two-columns {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        max-width: 75%;
      }
    }
  }
}

@media all and (max-device-width: 480px) {
  .interview-page {
    &__header {
      width: 100vw;
      padding: 0px 25px;

      &__intro {
        flex-direction: column;

        &__author {
          margin-bottom: 25px;
        }

        &__image {
          padding: 0px;
        }
      }

      &__text {
        columns: 1;
        max-width: 100%;

        &--one-column {
          max-width: 100%;
          margin-left: 0px;
        }

        &--two-columns {
          max-width: 100%;
          margin-left: 0px;
          flex-direction: column;
        }
      }
    }
  }
}