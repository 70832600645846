// import
@use 'src/styles/vars';

// header => contains navbar
header {
  position: relative;
  height: 150px;
  padding: 0px 125px;
  padding-top: calc(150px - 109px);

  .line__horizontal {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 1px;
    background-color: vars.$color-line;
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .navbar {
      &__items {
        display: flex;
        flex-direction: row;
      }

      &__item {
        height: 109px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 40px;
        font-size: 15px;

        &__home {
          font-family: vars.$font-primary;
          font-weight: 700;
          font-size: 45px;
          margin: 0px 40px;
          flex-grow: 1;
        }
  
        &__link {
          transition: 0.2s color, font-weight;
  
          &:hover {
            color: vars.$color-primary;
          }
  
          &--active {
            color: vars.$color-primary;
            font-weight: bold;
          }
        }
      }

      &__icons {
        height: 109px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 40px;
      }
  
      &__icon {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        a {
          height: 28.3px;
        }
  
        &:first-child {
          margin-right: 15px;
        }
  
        &:last-child {
          margin-left: 15px;
        }
        
        & svg {
          width: 39px;
          cursor: pointer;
          fill: vars.$color-line;
          transition: 0.2s fill;
  
          &:hover {
            fill: vars.$color-primary;
          }
        }
        
        &__item {
          &__select {
            z-index: 10;
            position: absolute;
            top: 74px;
            cursor: pointer;
            color: #000000;
          }
          
          &__option {
            user-select: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 90px;
            background-color: #FFFFFF;
            transition: 0.2s background-color;
            
            &:hover {
              background-color: vars.$color-primary;
              font-weight: bold;
            }
          }
        }
      }

      &__menu-burger {
        display: none;

        svg {
          height: 100%;
          width: 25px;
          fill: vars.$color-line;
        }
      }
    }
  }

  .header__menu {
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    background-color: vars.$color-background;
    width: 100vw;
    border-bottom: 1px solid vars.$color-line;

    &__items {
      font-size: 20px;
    }

    &__item {
      text-align: center;
      margin: 25px;
    }

    &__icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 25px;

      svg {
        width: 45px;
        fill: vars.$color-line;
        margin: 0px 25px;
      }
    }

    &__languages {
      display: flex;
      flex-direction: row;
      font-size: 20px;
      margin-bottom: 25px;
    }

    &__language {
      margin: 0px 25px;
    }
  }
}

@media all and (max-device-width: 480px) {
  .header {
    padding: 0px 25px 0px 25px;
    height: inherit;

    nav {
      .navbar {
        &__item {
          &__home {
            margin: 25px 0px;
            font-size: 30px;
          }
        }

        &__items {
          display: none;
        }

        &__icons {
          display: none;
        }

        &__menu-burger {
          display: block;
        }
      }
    }
  }
}