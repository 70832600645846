.interview-page {
  &__media {
    &__images {
      &__three {
        .interview-page__media__image {
          margin-bottom: 25px;
        }

        &--one {
          .interview-page__media__image {
            width: 50% !important;
          }
        }

        &--two {
          display: flex;
          flex-direction: row;

          .interview-page__media__caption { 
            padding: 0px 25px;
          }

          &--column {
            display: flex;
            flex-direction: column;

            .interview-page__media__image {
              &:last-child {
                margin-left: auto;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-device-width: 480px) {
  .interview-page {
    &__media {
      &__images {
        &__three {
          &--two {
            flex-direction: column;
            
            .interview-page__media__caption {
              padding: 25px 0px;
            }

            &--column {
              .interview-page__media__image {
                margin-bottom: 25px !important;
  
                &:last-child {
                  margin-bottom: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}