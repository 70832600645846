@use 'src/styles/vars';

.habiter {
  main {
    position: relative;
    margin-left: 125px;
    min-height: calc(100vh - 150px);
    display: flex;
    align-items: stretch;
  }

  // line next to the navbar
  .line__vertical {
    z-index: 10;
    position: absolute;
    top: -150px;
    left: 0px;
    width: 1px;
    height: 0%;
    background-color: vars.$color-line;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media all and (max-device-width: 480px) {
  .habiter {
    main {
      margin-left: 0px;
    }

    .line__vertical {
      display: none;
    }
  }
}