@use 'src/styles/vars';

.interview-page {
  &__audios {
    padding: 0px 45px;
    width: 60%;
    margin-top: 100px;

    &__title {
      margin: 0px 50px 50px 0px;
    }

    &__files {
      display: flex;
      flex-direction: column;
    }
  }
}

@media all and (max-device-width: 480px) {
  .interview-page {
    &__audios {
      width: 100%;
      padding: 0px 25px;

      &__title {
        margin: 0px 0px 50px 0px;
      }
    }
  }
}