.interview-page {
  &__quotes {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 100px;
    padding: 0px 45px;

    &__title {
      width: 40%;
    }

    &__resources {
      width: 40%;
    }

    &__resource {
      line-height: 1.3;
      margin-top: 16px;

      &__title {
        font-weight: bold;
      }
    }
  }
}


@media all and (max-device-width: 480px) {
  .interview-page {
    &__quotes {
      flex-direction: column;
      padding: 0px 25px;

      &__title, &__resources {
        width: 100%;
      }
      
    }
  }
}