@use 'src/styles/vars';

.interview {
  width: 222px;
  min-height: 100%;
  height: 100%;

  &--invert {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  &__image {
    background-color: vars.$color-primary;

    & img {
      width: 222px;
      height: 167px;
      transition: 0.2s opacity;
      filter: grayscale(100%);
      object-fit: cover;
      
      &:hover {
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }
  }

  &__text {
    padding: 17px 0px;
    font-family: vars.$font-primary;
    transition: 0.2s color;

    &:hover {
      color: vars.$color-primary;
    }

    &__title {
      font-size: 26px;
      font-weight: bold;
    }

    &__location {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}