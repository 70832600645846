@use 'src/styles/vars';

.interview-page {
  &__resources {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 100px;
    padding: 0px 45px;

    &__title, &__links {
      width: 40%;
    }

    &__link {
      font-size: 18px;
      padding-top: calc(73px / 2.7);
      padding-bottom: 40px;

      &:last-child {
        padding-bottom: 0px;
      }

      &__source {
        text-decoration: underline;
        transition: 0.2s color;

        &:hover {
          color: vars.$color-secondary;
        }
      }
    }
  }
}

@media all and (max-device-width: 480px) {
  .interview-page {
    &__resources {
      width: 100vw;
      flex-direction: column;
      padding: 0px 25px;

      &__title, &__links {
        width: 100%;
      }

      &__link {
        &__source {
          word-break: break-all;
        }
      }
    }
  }
}