@use 'src/styles/vars';

.introduction__choose-langue {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon {
    width: 42px;
    fill: vars.$color-line;
  }

  &__langues {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 55%;
    font-size: 25px;
    margin-top: 150px;
  }

  &__langue {
    cursor: pointer;
    transition: 0.2s color;

    &:hover {
      color: vars.$color-primary;
    }
  }
}

@media all and (max-device-width: 480px) {
  .introduction__choose-langue {
    width: 100%;
    height: inherit;
    
    &__langues {
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
    }
  
    &__langue {
      margin: 25px;
    }
  }
}