.interview-page {
  &__media {
    &__images {
      &__six {
        display: flex;
        flex-direction: row;

        &--column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 50%;
          margin-right: 25px;


          .interview-page__media__image {
            &:first-child {
              margin-bottom: 25px;
            }
          }

          .interview-page__media__caption {
            max-width: 80%;
            margin: 25px;
          }

          &--two {
            display: flex;
            flex-direction: column;

            .interview-page__media__image {             
              &:first-child {
                margin-bottom: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-device-width: 480px) {
  .interview-page {
    &__media {
      &__images {
        &__six {
          flex-direction: column !important;

          &--column {
            max-width: 100%;
            margin: 0px;

            .interview-page__media__caption {
              margin: 25px 0px;
            }

            .interview-page__media__image {
              margin-bottom: 25px !important;

              &:last-child {
                margin-bottom: 0px !important;
              }
            }
            
            .interview-page__media__caption {
              margin-top: 0px !important;
            }

            &--two {
              margin-bottom: 25px;

              .interview-page__media__image {
                margin-bottom: 25px !important;
  
                &:last-child {
                  margin-bottom: 0px !important;
                }
              }

            }
          }
        }
      }
    }
  }
}